import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal } from "bootstrap";

let inputDate = ''

document.addEventListener('DOMContentLoaded', function() {
  const calendarEls = document.querySelectorAll('.js_searchAreaCalendar')
  calendarEls.forEach(function(el) {
    const calendar = new Calendar(el, {
      timeZone: 'Asia/Tokyo',
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      validRange: function(currentDate) {
        const startDate = new Date(currentDate.valueOf());
        const endDate = new Date(currentDate.valueOf());

        endDate.setMonth(endDate.getMonth() + 6);

        return { start: startDate, end: endDate };
      },
      locale: 'ja',
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next',
      },
      height: 'auto',
      dayCellContent: function(e) {
        // 「日」という文言を消して、日付を表示する
        return e.dayNumberText.replace('日', '');
      },
      dateClick: function(date) {
        inputDate = date.dateStr
        $('.fc-daygrid-day').removeClass('isSelected')
        $(`.fc-daygrid-day[data-date="${inputDate}"]`).addClass('isSelected')
        $('.js_calendarAreaSelectedDate').text(inputDate)
      },
    })
    calendar.render()
  })
})

$(function() {
  // ページ読み込み時に受付中のクラスを付与
  if ($('#hiddenAccepting').val() == 'true') {
    $('.pc_accepting').prop('checked', true)
    $('.sp_accepting').prop('checked', true)
  } else {
    $('.pc_accepting').prop('checked', false)
    $('.sp_accepting').prop('checked', false)
  }

  // ページ読み込み時にランク区分検索のクラスを付与
  if ($('#hiddenRankTora').val() == 'false') {
    $('.check-box-tora').prop('checked', false)
  }
  if ($('#hiddenRankPanda').val() == 'false') {
    $('.check-box-panda').prop('checked', false)
  }
  if ($('#hiddenRankIruka').val() == 'false') {
    $('.check-box-iruka').prop('checked', false)
  }
  if ($('#hiddenRankPengin').val() == 'false') {
    $('.check-box-pengin').prop('checked', false)
  }
  if ($('#hiddenRankKodomo').val() == 'false') {
    $('.check-box-kodomo').prop('checked', false)
  }

  //「会員区分」にチェックありの場合
  $('.check_userstatus input[type=checkbox]').on('change', function() {
    if ($(this).prop('checked')) {
      $(this).parent('label').removeClass('is_uncheck')
    } else {
      $(this).parent('label').addClass('is_uncheck')
    }
  })

  $('.check_userstatus input[type=checkbox]').each(function() {
    if ($(this).prop('checked')) {
      $(this).parent('label').removeClass('is_uncheck')
    } else {
      $(this).parent('label').addClass('is_uncheck')
    }
  })

  // 市区町村入力
  // 都道府県
  const prefSelect = $('select[name="prefecture"]')
  const citySelect = $('select[name="city"]')

  const prefLoadStart = (loadingText = '読み込み中') => {
    prefSelect.prop('selectedIndex', 0)
    prefSelect.attr('disabled', 'disabled').find('option:eq(0)').html(loadingText)
  }
  const prefLoadEnd = () => {
    prefSelect.attr('disabled', false).find('option:eq(0)').html('都道府県を選択')
  }
  const cityLoadStart = (loadingText = '読み込み中') => {
    citySelect.prop('selectedIndex', 0)
    citySelect.attr('disabled', 'disabled').find('option:eq(0)').html(loadingText)
  }
  const cityLoadEnd = () => {
    citySelect.attr('disabled', false).find('option:eq(0)').html('市区町村を選択')
  }

  prefLoadStart()
  $.getJSON('/api/internal/v1/prefectures').done(function(json) {
    const prefs = json;
    const selectedKenId = $('input[name="prefecture"]')
    prefs.forEach(function(pref) {
      const $option = $("<option>").val(pref.ken_id).html(pref.ken_name)
      if (selectedKenId.length) {
        $option.attr('selected', selectedKenId.val() == pref.ken_id)
      }
      prefSelect.append($option)
    });
    if (selectedKenId.length && $('input[name="city"]').length === 0) {
      cityLoadStart()
      getCities(selectedKenId.val())
    }
    prefLoadEnd()
    if (selectedKenId.val() > 0) {
      const locationText = $(".js_is_filter_by_provider_pref_text span");
      locationText.text(prefSelect.eq(0).find('option:selected').text())
      if ($('#hiddenIsFilterByProviderPref').val() === 'true') {
        $(".js_is_filter_by_provider_pref").prop("checked", true)
      }
      $(".js_is_filter_by_provider_pref_wrapper").show();
    } else {
      $(".js_is_filter_by_provider_pref_wrapper").hide();
    }
  })

  citySelect.attr('disabled', 'disabled')
  // 市区町村
  $('select[name="prefecture"]').on('change', function() {
    citySelect
      .empty()
      .append('<option value="">市区町村を選択</option>');
    cityLoadStart()

    const kenId = $(this).val();
    const locationText = $(".js_is_filter_by_provider_pref_text span");
    if (kenId === '') {
      locationText.textContent = "";
      $(".js_is_filter_by_provider_pref_wrapper").hide();
      return
    }
    locationText.text($(this).find('option:selected').text());
    $(".js_is_filter_by_provider_pref_wrapper").show();
    getCities(kenId)
  })
  // 画面描画時に既に市区町村がセットされている場合の市区町村取得
  if ($('input[name="city"]').length && $('input[name="city"]').val() !== 0) {
    const selectedKenId = $('input[name="prefecture"]').val()
    const selectedCityId = $('input[name="city"]').val()
    if (selectedKenId !== '') {
      getCities(selectedKenId, selectedCityId)
    }
  }

  function getCities(kenId, cityId = null) {
    $.getJSON('/api/internal/v1/cities/' + kenId).done(function(json) {
      const cities = json;
      cities.forEach(function(city) {
        const $option = $("<option>").val(city.city_id).html(city.city_name);
        if (cityId != null) {
          $option.attr('selected', cityId == city.city_id)
        }
        citySelect.append($option)
      })
      cityLoadEnd()
    })
  }

  $('.btn_search').on('click', async function() {
    $('.btn_search').attr('disabled', 'disabled')
    $('.js_acceptPlace').attr('disabled', 'disabled')
    prefLoadStart('取得中...')
    cityLoadStart('取得中...')
    try {
      const position = await getCurrentLocation();
      const currentAddress = await searchByLocation(position.latitude, position.longitude)
      prefSelect.val(currentAddress.ken_id)
      getCities(currentAddress.ken_id, currentAddress.city_id)
      const locationText = $(".js_is_filter_by_provider_pref_text span");
      locationText.text(prefSelect.eq(0).find('option:selected').text())
      $(".js_is_filter_by_provider_pref_wrapper").show();
    } catch (error) {
      alert(error)
    } finally {
      prefLoadEnd()
      cityLoadEnd()
      $('.btn_search').attr('disabled', false)
      $('.js_acceptPlace').attr('disabled', false)
    }
  });
})

$(function() {
  // 入力欄フォーカスでチップを表示
  $('.js_searchDate').on('click', function() {
    $(this).next('.js_calendarArea').addClass('isVisible')
  })

  $('.js_acceptDate').on('click', function(e) {
    e.preventDefault()
    // 本日
    const date = new Date()
    const year = date.getFullYear()
    const month = ('00' + (date.getMonth() + 1)).slice(-2)
    const day = ('00' + date.getDate()).slice(-2)
    const today = `${year}-${month}-${day}`
    inputDate = inputDate ? inputDate : today

    // 入力欄にセット
    $(this).parents().siblings('.js_searchDateValue').val(inputDate)

    const searchDate = $(this).parents().siblings('.js_searchDate')
    if (inputDate !== '') {
      searchDate.html(`<i class="fa-light fa-calendar-days"></i><span>${inputDate}</span>`)
    } else {
      searchDate.empty()
    }

    // チップを消す
    $(this).parents('.js_calendarArea').removeClass('isVisible')
  })

  //日付選択をリセット
  $('.js_resetDate').on('click', function() {
    $(this).parents().siblings('.js_searchDateValue').val('')
    $(this).parents().siblings('.js_searchDate').html(`<i class="fa-light fa-calendar-days"></i>日付を選ぶ`)
  })


  // 対応エリア指定
  // 入力欄フォーカスでチップを表示
  $('.js_searchPlace').on('click', function() {
    $(this).addClass('is_active')
    $(this).next('.js_placeArea').addClass('isVisible')
  })

  // 適用ボタン押す
  $('.js_acceptPlace').on('click', function(e) {
    e.preventDefault()
    $(this).parents('.js_placeArea').removeClass('isVisible')

    const pref = $(this)
      .parent().siblings('.js_placeSelectList')
      .find('select[name="prefecture"]')
    const city = $(this)
      .parent().siblings('.js_placeSelectList')
      .find('select[name="city"]')
    let place =
      (pref.val() !== '' ? pref.find('option:selected').text() : '') +
      (city.val() !== '' ? ' ' + city.find('option:selected').text() : '')

    // 入力欄にセット
    $(this).parents().siblings('.js_searchPlaceValue').val(place)

    const searchPlace = $(this).parents().siblings('.js_searchPlace')
    if (place !== '') {
      searchPlace.html(`<i class="fa-light fa-location-dot"></i><span>${place}</span>`)
    } else {
      searchPlace.html(`<i class="fa-light fa-location-dot"></i>`)
    }
  })

  //選択をリセット
  $('.js_resetPlace').on('click', function() {
    $(this).parents().siblings('.js_searchPlaceValue').val('')
    $(this).parents().siblings('.js_searchPlace').html(`<i class="fa-solid fa-location-dot"></i>対応エリアを選ぶ`)
    $('select[name="prefecture"]').val('')
    $('select[name="city"]').val('')
    $('#hiddenPrefecture').val('')
    $('#hiddenCity').val('')
    $(".js_is_filter_by_provider_pref_wrapper").hide();
    $(".js_is_filter_by_provider_pref").prop("checked", false)
  })

  // //SP検索条件を全リセット
  $('.js_resetAll').on('click', function() {
    $('input[name="keyword"]').val('')
    $('.js_searchCategory').html(`<i class="fa-solid fa-folder"></i>カテゴリを選ぶ`)
    $('#spHiddenParentCategory').val('')
    $('#spHiddenSubCategory').val('')
    $('.js_searchPlaceValue').val('')
    $('.js_searchPlace').html(`<i class="fa-solid fa-location-dot"></i>対応エリアを選ぶ`)
    $('select[name="prefecture"]').val('')
    $('select[name="city"]').val('')
    $('select[name="price_lower"]').val('')
    $('select[name="price_upper"]').val('')
    $('select[name="sex"]').val('')
    $('.sp_accepting').prop('checked', false)
  })

  //「現在地から探す」にチェック入っている時は、地域選択のプルダウンは非活性
  $('.js_current_location').on('change', function() {
    if ($(this).prop('checked')) {
      $('.js_placeSelectList').addClass('is_disabled')
    } else {
      $('.js_placeSelectList').removeClass('is_disabled')
    }
  })

  //「エリアで探す」から遷移した場合は「対応エリアを選ぶ」にフォーカス
  let areaClicked
  $('.js_topSearchArea').on('click', function() {
    areaClicked = true
  })

  const spSearchModal = document.getElementById("spSearchModal");
  if (spSearchModal === null) return;
  spSearchModal.addEventListener("shown.bs.modal", function(e) {
    setTimeout(function() {
      if (areaClicked) {
        $('.js_searchPlace').addClass('is_active')
        $('.js_placeArea').addClass('isVisible')
        areaClicked = false
      }
    }, 300)
  })

  //その他の条件
  //ボタンクリック時（PC）
  $('.js_searchEtc').on('click', function() {
    $(this).parents().next('.js_etcArea').toggleClass('is_show').slideToggle(200)
    $(this).parent('.js_searchEtc_wrap').toggleClass('is_open')
  })

  //ボタンクリック時（SP）
  $('.js_searchEtcSp').on('click', function() {
    $('#spSearchModal .searchArea_item.etc').toggleClass('is_open')
    $('#spSearchModal .js_etcArea').slideToggle(200)
  })

  //適応ボタンクリック時
  $('.js_acceptEtc').on('click', function(e) {
    e.stopPropagation()
    $(this).parents().siblings().find('.js_searchEtc_wrap').removeClass('is_open')
    $(this).parents('.js_etcArea').slideUp(200)
  })

  //「受付中のみ表示」にチェックありの場合
  $('.sort_accepting input[type=checkbox]').on('change', function() {
    if ($(this).prop('checked')) {
      $(this).parent('.sort_accepting').addClass('is_checked')
    } else {
      $(this).parent('.sort_accepting').removeClass('is_checked')
    }
  })

  // 都道府県のテキストがすでにある場合の文言をセット
  const hiddenSearchPlaceText = document.getElementById("hiddenSearchPlace").value;

  if (hiddenSearchPlaceText != '対応エリアを選ぶ' && hiddenSearchPlaceText != '') {
    $('.js_searchPlace').html(`<i class="fa-light fa-location-dot"></i>${hiddenSearchPlaceText}`)
  } else {
    $('.js_searchPlace').html(`<i class="fa-solid fa-location-dot"></i>対応エリアを選ぶ`)
  }

  // カテゴリ選択時タブを開く
  $('.js_searchCategory').on('click', function() {
    $('.main_cat li').addClass("open")
  })

  // カテゴリがすでにある場合カテゴリの文言をセット
  const spHiddenParentCategoryName = document.getElementById("parent_category_name")?.value || "";
  const spHiddenSubCategoryName = document.getElementById("sub_category_name")?.value || "";

  const displayCategoryName = spHiddenParentCategoryName + spHiddenSubCategoryName
  if (displayCategoryName !== '') {
    $('.js_searchCategory').html(`<i class="fa-light fa-folder"></i><span>${displayCategoryName}</span>`)
  } else {
    $('.js_searchCategory').html(`<i class="fa-solid fa-folder"></i>カテゴリを選ぶ`)
  }

  // カテゴリ選択時タブを開く(トップ画面)
  $('.js_topSearchCategory').on('click', function() {
    $('.main_cat li').addClass("open")
  })

  //カテゴリを選択時の挙動（sp）
  $('#spCategorySelectModal .sub_cat_inner').on('click', function() {
    //モーダルを閉じる
    const categoryModal = Modal.getInstance(document.getElementById("spCategorySelectModal"))
    categoryModal.hide()
    const searchModal = Modal.getInstance(document.getElementById("spSearchModal"))
    searchModal.show()

    // 入力欄にセット
    const cat = $(this).parents('.sub_cat_wrap').prev().find('.cat_name span').text()
    const subCat = $(this).children('.cat_name').text()
    let selectCat = (cat + ' ' + subCat)
    if (selectCat !== '') {
      $('.js_searchCategory').html(`<i class="fa-light fa-folder"></i><span>${selectCat}</span>`)
    } else {
      $('.js_searchCategory').html(`<i class="fa-solid fa-folder"></i>カテゴリを選ぶ`)
    }
  })

  // チップ以外クリックでチップを閉じる
  const tipAreas = [
    ['.js_placeArea', '.js_searchPlace'],
    ['.js_calendarArea', '.js_searchDate'],
  ]
  tipAreas.forEach((area) => {
    $(document).on('click', function(e) {
      if (
        !$(e.target).closest(area[0]).length &&
        !$(e.target).closest(area[1]).length
      ) {
        $(area[0]).removeClass('isVisible')
      }
      if (!$('.js_placeArea').hasClass('isVisible')) {
        $('.js_searchPlace').removeClass('is_active')
      }
    })
  })

  // チップ消すボタン
  $('.js_searchTipClose').on('click', function(e) {
    e.preventDefault()
    $(this).closest('.js_searchTipArea').removeClass('isVisible')
  })

  $('.search_detail_reset').on('click', function(e) {
    e.preventDefault()
    window.location.href = '/search';
  })
})

function getCurrentLocation() {
  return new Promise(function(resolve, reject) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        resolve({latitude: latitude, longitude: longitude});
      }, function(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            reject("位置情報の取得が許可されていません");
            break;
          case error.POSITION_UNAVAILABLE:
            reject("位置情報が利用できません");
            break;
          case error.TIMEOUT:
            reject("位置情報の取得にタイムアウトしました");
            break;
          default:
            reject("位置情報の取得中にエラーが発生しました");
            break;
        }
      });
    } else {
      reject('ブラウザが位置情報の取得に対応していません');
    }
  });
}
const searchByLocation = async (latitude, longitude) => {
  const url = `/api/internal/v1/current_address?latitude=${latitude}&longitude=${longitude}`;
  const response = await fetch(url);
  if (!response.ok) {
    switch(response.status) {
      case 400:
        throw new Error('現在地を取得できませんでいた。不正なリクエストです');
      case 404:
        throw new Error('現在点が見つかりませんでした');
      default:
        throw new Error('サーバエラーのため現在位置の取得失敗しました。');
    }
  }
  return await response.json();
};
